<template>
  <div class="student-card">
    <div class="info">
      <div>
        <span>院系：</span>
        <el-select v-model="depid" placeholder="请选择" @change="changeSystem">
          <el-option v-for="item in systemList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>
        <span>班级：</span>
        <el-select v-model="classid" placeholder="请选择" clearable @change="changeClass">
          <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id" />
        </el-select>

        <span>姓名/学号：</span>
        <el-select v-model="studentInfo" filterable remote reserve-keyword placeholder="注意：不包含考评人员"
          :remote-method="remoteMethod" @change="studentChange" class="w180">
          <el-option v-for="item in searchStudentList" :key="item.id" :value="item.id" :label="item.nameNum">
          </el-option>
        </el-select>

      </div>
      <div>
        <el-button type="primary" plain @click="exportCard">导出卡片</el-button>
        <el-button type="primary" @click="getData">搜索</el-button>
      </div>
    </div>
    <div>
      <el-table class="mgt16" ref="multipleTable" :data="studentData" tooltip-effect="dark" style="width: 100%"
        element-loading-text="Loading" border fit highlight-current-row>
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="序号" align="center" type="index" width="60"></el-table-column>
        <el-table-column prop="dname" align="left" label="院系"></el-table-column>
        <el-table-column prop="cname" align="left" label="班级"></el-table-column>
        <el-table-column prop="name" align="left" label="姓名"></el-table-column>
        <el-table-column prop="studentnum" align="left" label="学号">
          <template slot-scope="scope">
            <span class="tc_blue" v-bind:title="scope.row.id">{{ scope.row.studentnum }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="fractionnum" align="left" label="分数">
          <template slot-scope="scope">
            <span v-if="scope.row.fractionnum >= 60" class="tc_blue" v-bind:title="scope.row.id">{{ scope.row.fractionnum
            }}</span>
            <span v-else-if="scope.row.fractionnum < 60" class="tc_red">{{ scope.row.fractionnum }}</span>
            <span v-else>{{ scope.row.fractionnum }}</span> 分
          </template>
        </el-table-column>
        <el-table-column prop="sex" align="left" label="性别"></el-table-column>
        <el-table-column prop="classnum" align="left" label="班号"></el-table-column>
        <el-table-column prop="address" align="center" label="操作" width="110px">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="handleView(scope.row)">生成卡片</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
        :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
    <el-dialog title="详情" v-if="dialogFormVisible" :visible.sync="dialogFormVisible" :before-close="handleClose"
      width="40%" minWidth="600px">
      <card-preview :cardData="cardData" :isClass="isClass" />
    </el-dialog>
  </div>
</template>
<script>
import * as api from "@/api/users";
import CardPreview from "@/views/components/CardPreview.vue";
import table2excel from 'js-table2excel'

export default {
  name: "studentCard",
  components: {
    CardPreview,
  },
  data() {
    return {
      depid: null, //院系
      classid: null, //班级
      name: "", //姓名

      systemList: [], //院系列表
      classList: [], //班级列表
      studentData: [],
      total: 0,
      page: 1,
      pageSize: 10,
      dialogFormVisible: false,
      cardData: [],
      isClass: false,

      studentId: 0, // 所选学生的ID
      studentInfo: "",
      searchStudentList: [], //搜索学生列表
    };
  },
  mounted() {
    this.getSystemList();
    this.getData();
  },
  methods: {
    getStudentList(val) {
      api.selectBySearchName({ searchName: val, searchType: '1' }).then((res) => {
        if (res.status === 1) {
          let arr = res.data.data;
          if (arr != null) {
            arr.forEach((res) => {
              res.nameNum = res.num + "  " + res.name;
            });
            this.searchStudentList = arr;
          }
        }
      });
    },
    // 姓名/学号
    remoteMethod(query) {
      this.getStudentList(query); // 调用上面的方法
    },
    studentChange(id) {
      this.studentId = id;
      for (let i = 0; i < this.searchStudentList.length; i++) {
        if (this.searchStudentList[i].id == id) {
          this.name = this.searchStudentList[i].name;
          break;
        }
      }
    },

    // 获取院系列表
    getSystemList() {
      let { page, pageSize } = this;
      let params = {
        page,
        pageSize,
      };
      // 院系接口
      api
        .getSystemList(params)
        .then((res) => (this.systemList = res.data.records));
    },
    // 切换系部
    changeSystem(id) {
      api.getCardArgumentDetail({ depid: id }).then((res) => {
        let classList = res.data.records.filter((item) => item.depid === id);
        this.classList = classList;
        this.classid = null;
        if (classList != null && classList.length > 0) {
          this.classid = classList[0].id;
        }
      });
    },
    changeClass(id) {
      this.classid = id;
    },
    // 页容量切换
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    // 当前页切换
    handleCurrentChange(val) {
      this.page = val;
      this.getData();
    },
    // 获取数据列表
    getData() {
      let { page, pageSize, depid, classid, name } = this;
      let params = {
        page,
        pageSize,
        depid,
        classid,
        name,
        identity: 0,
      };
      api.getUserList(params).then((res) => {
        if (res.status == 1) {
          this.total = res.data.total;
          this.studentData = res.data.rows;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 详情
    handleView(row) {
      this.dialogFormVisible = true;
      this.cardData = row;
    },
    // 关闭弹窗
    handleClose(done) {
      done();
    },

    /**
     * 导出卡片
     */
    exportCard() {
      let _this = this
      // 学生ID集合
      let userIds = [];
      // 获取选中的用户信息
      let rows = _this.$refs.multipleTable.selection;
      rows.forEach(function (element) {
        userIds.push(element.id);
      });

      const column = [
        { title: '院系', key: 'deptName', type: 'text' },
        { title: '班级', key: 'className', type: 'text' },
        { title: '姓名', key: 'userName', type: 'text' },
        { title: '学号', key: 'studentNum', type: 'text' },
        { title: '班号', key: 'classNum', type: 'text' },
        { title: '二维码', key: 'phone', type: 'image', width: 80, height: 80 }
      ]

      // 获取用户名片列表信息接口
      api.getUserBusinessCardList(userIds).then((res) => {
        if (res.status == 1) {
          console.log("获取用户名片列表信息接口响应：" + res.data);

          let datas = res.data;
          const excelName = '学生卡片';// 文件名称
          table2excel(column, datas, excelName);// 生成Excel表格，自动下载

        } else {
          this.$message.error(res.msg);
        }
      });

    }




  },
};
</script>
